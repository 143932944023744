import {Component, OnInit} from '@angular/core';
import {MatIconRegistry} from '@angular/material';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  pages$;

  constructor(private registry: MatIconRegistry, private sanitizer: DomSanitizer) {

  }

  ngOnInit(): void {
    this.registry.addSvgIconSet(this.sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/set.svg'));
  }
}
