import {DataSource} from '@angular/cdk/collections';
import {MatSort} from '@angular/material';
import {map, startWith} from 'rxjs/operators';
import {combineLatest, Observable} from 'rxjs';

// TODO: Replace this with your own data model type
export interface ImageListItem {
  title: string;
  status: string;
  hash: number;
  url: string;
  pages: string[];
}


/**
 * Data source for the ImageList view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class ImageListDataSource extends DataSource<ImageListItem> {

  constructor(private data: Observable<ImageListItem[]>, private sort: MatSort) {
    super();
  }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(): Observable<ImageListItem[]> {
    // Combine everything that affects the rendered data into one update
    // stream for the data-table to consume.
    const dataMutations = [
      this.data,
      this.sort.sortChange.pipe(startWith(null))
    ];

    console.log('connected');

    return combineLatest(...dataMutations).pipe(map(([data]) => {
      return this.getSortedData(data);
    }));
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect() {
  }

  /**
   * Sort the data (client-side). If you're using server-side sorting,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getSortedData(data: ImageListItem[]) {
    if (!this.sort.active || this.sort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      const isAsc = this.sort.direction === 'asc';
      switch (this.sort.active) {
        case 'title':
          return compare(a.title, b.title, isAsc);
        case 'status':
          return compare(a.status, b.status, isAsc);
        default:
          return 0;
      }
    });
  }
}

/** Simple sort comparator for example ID/Name columns (for client-side sorting). */
function compare(a, b, isAsc) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
