import { Component, OnInit } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';

@Component({
  selector: 'app-page-list',
  templateUrl: './page-list.component.html',
  styleUrls: ['./page-list.component.scss']
})
export class PageListComponent implements OnInit {

  pages$;

  constructor(private db: AngularFirestore) { }

  ngOnInit() {
    this.pages$ = this.db.collection('pages', ref => ref.orderBy('batch', 'asc')).valueChanges();
  }

}
