import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {filter, first, map, skip, startWith, switchMap} from 'rxjs/operators';
import {AngularFirestore} from '@angular/fire/firestore';
import {AngularFireFunctions} from '@angular/fire/functions';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-page-details',
  templateUrl: './page-details.component.html',
  styleUrls: ['./page-details.component.scss'],
})
export class PageDetailsComponent implements OnInit {

  page$;
  images$: Observable<any[]>;
  trackedImages$;
  ignoredImages$;
  loaded = false;
  loadingImages = false;

  constructor(private route: ActivatedRoute, private db: AngularFirestore, private functions: AngularFireFunctions) {
  }

  ngOnInit() {
    this.page$ = this.route.paramMap.pipe(
      switchMap((params: ParamMap) => this.db.collection('pages').doc(params.get('id').toString()).valueChanges()),
      startWith({})
    );
    this.images$ = this.route.paramMap.pipe(
      switchMap((params: ParamMap) => this.db.collection('images', ref => ref.where('pages', 'array-contains', params.get('id').toString())).valueChanges()),
      startWith([]),
    );
    this.page$.pipe(filter(page => page && page['id']), first()).subscribe(() => this.loaded = true);
    this.trackedImages$ = this.images$.pipe(map(images => images.filter(image => image.tracked)));
    this.ignoredImages$ = this.images$.pipe(map(images => images.filter(image => !image.tracked)));
  }

  scrapeImages() {
    this.loadingImages = true;
    this.page$.pipe(skip(1), first()).subscribe(page => {
      this.functions.httpsCallable('imageFinder')({
        url: page.urllink,
        pageId: page.id
      }).subscribe(() => this.loadingImages = false, console.error);
    });
  }

  updateImageStatus(image) {
      this.db.collection('images').doc(image.hash).update({tracked: !image.tracked});
  }
}
