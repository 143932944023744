import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FlexLayoutModule} from '@angular/flex-layout';
import {AngularFireModule} from '@angular/fire';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {environment} from '../environments/environment';
import {
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatIconModule,
  MatListModule,
  MatProgressBarModule,
  MatToolbarModule, MatTableModule, MatPaginatorModule, MatSortModule, MatMenuModule
} from '@angular/material';
import {HttpClientModule} from '@angular/common/http';
import {RouterModule, Routes} from '@angular/router';
import {PageDetailsComponent} from './page-details/page-details.component';
import {AngularFireFunctionsModule, FunctionsRegionToken} from '@angular/fire/functions';
import {PageListComponent} from './page-list/page-list.component';
import { ImageListComponent } from './image-list/image-list.component';

const routes: Routes = [
  {
    path: 'pages', component: PageListComponent, children: [
      {path: ':id', component: PageDetailsComponent}
    ]
  },
  {path: 'images', component: ImageListComponent},
  {path: '', pathMatch: 'full', redirectTo: 'pages'}
];

@NgModule({
  declarations: [
    AppComponent,
    PageDetailsComponent,
    PageListComponent,
    ImageListComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(routes),
    FlexLayoutModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule.enablePersistence({experimentalTabSynchronization: true}),
    AngularFireFunctionsModule,
    MatIconModule,
    MatToolbarModule,
    MatListModule,
    MatCardModule,
    MatButtonModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatMenuModule
  ],
  providers: [{provide: FunctionsRegionToken, useValue: 'us-central1'}],
  bootstrap: [AppComponent]
})
export class AppModule {
}
