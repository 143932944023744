import {Component, OnInit, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material';
import {ImageListDataSource, ImageListItem} from './image-list-datasource';
import {AngularFirestore} from '@angular/fire/firestore';

@Component({
  selector: 'app-image-list',
  templateUrl: './image-list.component.html',
  styleUrls: ['./image-list.component.scss'],
})
export class ImageListComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  dataSource: ImageListDataSource;
  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['pages', 'image', 'title', 'status'];
  images$;
  statusToClass = {
    'to do': 'todo',
    'in progress': 'inprogress',
    'in review': 'inreview',
    'approved': 'approved'
  };
  statusOptions = Object.keys(this.statusToClass);

  constructor(private db: AngularFirestore) {
  }

  ngOnInit() {
    this.images$ = this.db.collection<ImageListItem>('images', ref => ref.where('tracked', '==', true)).valueChanges();
    this.dataSource = new ImageListDataSource(this.images$, this.sort);
  }

  updateStatus(image, status) {
    this.db.collection('images').doc(image.hash).update({status});
  }
}
